* {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --black: black;
  --white: white;
  --main-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

body {
  font-size: 16px;
  font-family: var(--main-font);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 3px;
  padding: 0.5rem;
  cursor: pointer;
}

button {
  display: flex;
  justify-content: center;
  font-family: inherit;
  padding: 10px 12px;
  border-radius: 3px;
  border: none;
  line-height: 1.5;
  cursor: pointer;
  background-color: steelblue;
  color: white;
  transition: transform 0.2s ease-in-out;
}

button:hover {
  opacity: 0.9;
}

button:active {
  transform: scale(0.98);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  font-family: inherit;
  width: 100%;
  background: transparent;
  cursor: pointer;
  border: 1px solid lightsteelblue;
  padding: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

input:focus {
  border-color: steelblue;
}
