.Year__container {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  align-items: stretch;
  padding: 12px;

  .bgImage {
    min-height: 450px;
    max-width: 100%;
    background-size: cover;
    background-position: top center;
    background-color: WhiteSmoke;
    border-radius: 3px;
  }
}
