.SignIn__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .SignIn__sub_container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 360px;
  }
}
